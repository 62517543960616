import Vue from 'vue'
import Router from 'vue-router'

import Home from '../views/Home.vue'
import Test from '../views/Test.vue'



Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path : '/',
      name:'Home',
      component: Home,
      props:true
    },

    {
      path : '/test',
      name:'Test',
      component: Test,
      props:true
    },
  ]
})
