<template>
  <div>
  <v-toolbar-items class="ft align-center text-no-wrap hidden-sm-and-down">
      <li><a href="#">Produk</a></li>
      <li><a href="#">Pengunjung</a></li>
      <li><a href="#">Mitra</a></li>
      <li><a href="#">Sosial Media</a></li>
  </v-toolbar-items>

  <v-toolbar-items class="pad align-center justify-center hidden-md-and-up">
    <v-menu
      open-on-hover
      width="30%"
      offset-y>
        <template v-slot:activator="{ on, attrs }">
        <li class="px-0">
          <v-hover v-slot="{hover}">
            <v-btn v-bind="attrs" v-on="on" class="pa-5 me-5" outlined color="black" :style="{'background-color': hover ? '#FFB84C' : ''}" >
              <v-icon large color="black">mdi-menu</v-icon>
            </v-btn>
          </v-hover>
        </li>
        </template>

        <v-list class="pa-0">
          <v-list-item-title class="bg">

          <div>
          <li><v-btn text><a class="" href="#">Produk</a></v-btn></li>
          </div>
          
          <div>
          <li><v-btn text><a href="#">Pengunjung</a></v-btn></li>
          </div>

          <div>
          <li><v-btn text><a href="#">Mitra</a></v-btn></li>
          </div>

          <div>
          <li><v-btn text><a href="#">Sosial Media</a></v-btn></li>
          </div>
          
          </v-list-item-title>
        </v-list>
      </v-menu>
    </v-toolbar-items>

  </div>
</template>

<script>

  export default {
    name: 'header-page',
    components : {

    },

  data (){
    return{

    }
  },

  methods : {

  },

  created(){

  }

  }
  
</script>

<style>
.bg{
  background: black;
}
.ft{
  margin-left: 45px;
  font-family: louis;
  font-size: 26px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 1.04px;
  padding-top: 20px;
  padding-bottom: 70px;
}

.pad{
  padding: 20px 20px;
}

li{
  display: inline; 
  padding: 0px 30px;
}

a{
  color: white !important;
  text-decoration:none;
}

a:hover{
  color: #FFB84C!important;
}

</style>