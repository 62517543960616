<template>
  <div class="">
    <v-img class="homebackground" src="../assets/home background.png">
      <div class="headerMargin">
        <v-toolbar-items class="ft align-center text-no-wrap hidden-xs-only">
          <li><a href="#produk">Produk</a></li>
          <li><a href="#pengunjung">Pengunjung</a></li>
          <li><a href="#mitra">Mitra</a></li>
          <li><a href="#sosial">Sosial Media</a></li>
        </v-toolbar-items>
      </div>

    <v-toolbar-items class="pad align-center justify-center hidden-sm-and-up">
      <v-menu
        open-on-hover
        width="30%"
        offset-y>
          <template v-slot:activator="{ on, attrs }">
          <li class="px-0">
            <v-hover v-slot="{hover}">
              <v-btn v-bind="attrs" v-on="on" class="pa-5 me-5" outlined color="black" :style="{'background-color': hover ? '#FFB84C' : ''}" >
                <v-icon large color="black">mdi-menu</v-icon>
              </v-btn>
            </v-hover>
          </li>
          </template>

        <v-list class="pa-0">
          <v-list-item-title class="bg">

          <div>
          <li><v-btn text><a class="" href="#produk">Produk</a></v-btn></li>
          </div>
          
          <div>
          <li><v-btn text><a href="#pengunjung">Pengunjung</a></v-btn></li>
          </div>

          <div>
          <li><v-btn text><a href="#mitra">Mitra</a></v-btn></li>
          </div>

          <div>
          <li><v-btn text><a href="#sosial">Sosial Media</a></v-btn></li>
          </div>
          
          </v-list-item-title>
        </v-list>
      </v-menu>
    </v-toolbar-items>

      <div class="pt-8">
        <v-row class="pt-15 ">
          <v-col class="content-1a" cols="0" sm="0" md="6" lg="6" xl="6">
            <v-img class="nonemobile" min-width="500" max-width="500" src="../assets/mobile.png" alt="">
              <div class="d-flex align-end justify-center pContainer">
                <v-img class="nonemobile" min-width="442" max-width="442" src="../assets/Mask group.png"></v-img>
              </div> 
            </v-img>
          </v-col>

          <!-- <v-col class="d-flex align-end justify-end pe-15 fillHome" cols="6">
            <v-img max-width="500" src="../assets/mobilelogo2.png" alt="">
            </v-img>
          </v-col> -->

          <v-col class="content-1b marginb text-no-wrap" cols="12" sm="12" md="6" lg="6" xl="6">
            <p class="font1 ">Eat Time</p>
            <p class="font2">Scan di Tempat<br>
              Pesan di Tempat<br>
              Bayar di Tempat</p>
            <p class="font3">Bebas Antri</p>

            <v-img style="cursor: pointer;" class="googleLogo" src="../assets/googleplaylogo.png" ></v-img>
          </v-col>
        </v-row>
        
      </div>
    </v-img>

    <div class="marginb">
      <v-row style="padding: 40px;" >
        <v-col class="content-2a pe-15 hidden-sm-and-down" cols="12" sm="12" md="6" lg="6" xl="6">
          <v-img class="" min-width="350" max-width="512" src="../assets/logohome2.png"></v-img>
        </v-col>

        <v-col id="produk" class="content-2b" cols="12" sm="12" md="6" lg="6" xl="6">
          <p class="font4 text-no-wrap">Eat Time</p>

          <p class="font5 text-justify">Sediakan makanan kepada pengunjung anda tidak perlu 
            berlama lama dan melakukan antrian yang panjang. 
            Cukup sediakan barcode di meja makan, scan menu, 
            pilih menu lalu bayar menggunakan E-Wallet. 
            EatTime memudahkan restoran anda sekaligus 
            pengunjung anda dalam memesan makanan</p>
          
        </v-col>
      </v-row>
    </div>

    <div style="background-color: #9D8686;" class="paddingb3">
      <div class="paddingt paddingb">
         <p id="pengunjung" class="font6">MEMPERMUDAH KONSUMEN</p>
      </div>

      <v-row >
        <v-col class="content-3a paddingr hidden-sm-and-down" cols="12" sm="12" md="4" lg="5" xl="5">
          <img width="172" height="732" src="../assets/timelinelogo.png" />
        </v-col>

        <v-col class="content-3b" cols="12" sm="12" md="7" lg="7" xl="7">
          <p class="font7">Scan Menu di Meja</p>
          <p class="font8 paddingb2">Pengunjung melakukan scan barcode menggunakan smartphone yang disediakan pada setiap meja</p>

          <p class="font7">Pilih Menu</p>
          <p class="font8 paddingb">Pengunjung memilih menu yang ada di aplikasi yang langsung terintegrasi pada tablet yang ada di kasir</p>

          <p class="font7">Bayar Ditempat</p>
          <p class="font8">Pengunjung dapat melakukan pembayaran menggunakan e-money ataupun secara cash dengan waiter akan mengambil uang saat mendatangkan pesanan</p>

          <br>

        </v-col>
      </v-row>
    </div>

    <div class="content-4a">
    
      <div class="text-center">
        <p id="mitra" class="font6">KELOLA PESANAN DENGAN MUDAH</p>
   
        <br>

        <div class="d-flex justify-center pb-5">
        <p class="font9">Proses pesanan anda secara fleksibel. 
          Anda dapat menentukan jika menu telah habis dan menambahkan diskon pada setiap menu tertentu. 
          Anda juga dapat membuat struk dalam bentuk digital dan cetak.</p>
        </div>

        <br>

        <v-img class="mx-auto" style="max-width: 700px;" src="../assets/ipadlogo.png" ></v-img>
      </div>
    </div>

    <div style="background-color: #292121; padding:100px 40px 130px 40px; text-align: center;">
      <v-row class="nonemobile">
        <v-col class="d-flex justify-end align-center" cols="6" >
          <p class="font1">Eat Time</p>
        </v-col>

      
        <v-col class="d-flex flex-column align-start justify-center paddingl" cols="6"  >
          <p class="font2">Scan di Tempat<br>
            Pesan di Tempat<br>
            Bayar di Tempat</p>
          <p class="font3">Bebas Antri</p>
        </v-col>
     
      </v-row>

      <v-img style="cursor: pointer;" class="mx-auto mt-14 mb-12" max-width="344" max-height="133" src="../assets/googleplaylogo.png" ></v-img>

      <hr class="hrline mx-auto mb-14">
      <p class="font10 mb-14">Ikuti Sosial Media Kami</p>

      <v-row @click="goToIg" class="justify-center">
        <img style="max-width: 200px; height: 100px; cursor: pointer;" src="../assets/iglogo.png"/>
        <p style="cursor: pointer;" class="font11 ms-15">@eattimeapp</p>
      </v-row>
    </div>

    <div>
      <Footer id="sosial"></Footer>
    </div>
  </div>
</template>

<script>
import footer from '../components/Footer.vue';

  export default {
    name: 'home-page',
    components : {
      'Footer':footer,
    },

  data (){
    return{

    }
  },

  methods : {

    goToIg(){
      window.open("https://www.instagram.com/eattimeapp/", '_blank').focus();
    },
    goToStore(){
      window.open(this.linkshopee, '_blank').focus();
    }

  },

  created(){

  }

  }
  
</script>

<style>
.body {
 height: 100%;
}

html {
  scroll-behavior: smooth;
}

.pad{
  padding: 20px 20px;
}

.content-1a{
  display:flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 60px;
}

.content-1b{
  display:flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  padding-left: 60px;
}

.content-2a{
  display:flex;
  align-items: center;
  justify-content: flex-end;
}

.content-2b{
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.content-3a{
  display: flex;
  justify-content: flex-end;
}

.content-3b{
  display:flex;
  flex-direction: column;
  justify-content: center;
}

  .content-4a{
    background-color: #7D5A5A;
    padding-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
  }

.googleLogo{
  max-width: 344px; 
  max-height: 133px;
}

.ft{
  margin-left: 45px;
  font-family: louis;
  font-size: 26px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 1.04px;
  padding-top: 20px;
}

li{
  display: inline; 
  padding: 0px 30px;
}

a{
  color: white !important;
  text-decoration:none;
}

a:hover{
  color: #FFB84C!important;
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
}

.pContainer{
  height: 100%;
}

.paddingt{
  padding-top:100px;
}

.paddingb{
  padding-bottom:85px;
}

.paddingb2{
  padding-bottom:90px;
}

.paddingb3{
  padding-bottom:150px;
}

.paddingr{
  padding-right: 70px;
}

.paddingl{
  padding-left: 140px;
}

.margint{
  margin-top: 100px;
}

.marginb{
  margin-bottom: 120px;
}

.fillHome{
  height: 1278px;
}

.hrline{
  height:4px;
  background-color: white;
  width: 60%;
}

.font1{
  color: #FFF;
  font-family: high;
  font-size: 128px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 7.68px;
}

.font2{
  color: #FFF;
  text-align: justify;
  font-family: louis;
  font-size: 40px;
  font-style: normal;
  font-weight: bold;
  line-height: 132.49%; /* 52.996px */
  letter-spacing: 1.6px;
}

.font3{
  color: #FFF;
  font-family: louis;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 5.52px;
}

.font4{
  color: #7D5A5A;
  font-family: high;
  font-size: 75px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.5px;
}

.font5{
  color: #000;
  text-align: justify;
  font-family: louis;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 141.441%; /* 33.946px */
  letter-spacing: 0.6px;
}

.font6{
  color: #FFF;
  text-align: center;
  font-family: louis;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 141.441%; /* 90.522px */
  letter-spacing: 6.4px;
}

.font7{
  color: #FFF;
  font-family: louis;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 141.441%; /* 56.577px */
  letter-spacing: 2.4px;
}

.font8{
  color: #FFF;
  text-align: justify;
  font-family: louis;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 141.441%; /* 33.946px */
  max-width: 550px;
}

.font9{
  color: #FFF;
  font-family: louis;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 141.441%; /* 33.946px */
  max-width: 650px;
}

.font10{
  color: #FFF;
  text-align: center;
  font-family: louis;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4.6px;
}

.font11{
  color: #FFF;
  text-align: center;
  font-family: high;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.84px;
}

@media only screen and (max-width: 3804px) {

}

@media only screen and (max-width: 1904px) {

}

@media only screen and (max-width: 1264px) {
  .font1{
    font-size:90px !important;
  }

  .content-1b{
    padding-left: 40px !important;
  }

  .marginb{
    margin-bottom: 40px !important;
  }

  .content-3a{
    justify-content: center;
  }

  .paddingr{
    padding-right: 40px !important;
  }

  
}

@media only screen and (max-width: 960px) {
  .nonemobile {
    display: none ;
  }

  .ft{
    font-size: 20px !important;
    margin-left: 0px !important;
  }

  li{
    padding: 0px 20px !important;
  }

  .content-1b{
    display:flex;
    align-items: center !important;
    justify-content: flex-end;
    flex-direction: column;
    padding-left: 10px !important;
  }

  .content-2b{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

  .content-3b{
    align-items: center;
  }


  
}

@media only screen and (max-width: 600px) {
  .nonemobile {
    display: none ;
  }

  .homebackground {
    background-size: cover;
  }

  .googleLogo{
    max-width: 250px;
    max-height: 100px;
  }

  .content-4a{
    padding: 40px 15px 0px 15px;
  }

  .paddingt{
    padding-top: 40px !important;
  }

  .paddingb{
    padding-bottom: 40px !important;
  }

  .paddingb2{
    padding-bottom: 40px !important;
  }

  .paddingb3{
    padding-bottom: 40px !important;
  }

  .marginb{
    margin-bottom: 40px !important;
  }

  .font1{
    font-size:65px !important;
  }

  .font2{
    font-size:35px !important;
  }

  .font4{
    font-size:60px !important;
  }

  .font5{
    font-size:16px !important;
    min-width: 300px;
  }

  .font6{
    font-size:30px !important;
  }

  .font7{
    font-size:30px !important;
  }

  .font8{
    font-size: 15px !important;
    min-width: 300px !important;
    max-width: 550px !important;
    padding: 0 15px;
  }

  .font9{
    font-size: 16px;
  }
}



</style>