<template>
  <div class="">

    <v-img class="" src="../assets/home background.png">
      <div class="headerMargin">
        <Header></Header>
      </div>

      <div class=" pt-8 ">
        <v-row class="pt-15 ">
          <v-col class="d-flex align-end justify-end pe-15" cols="6">
            <v-img class="pt-2" min-width="500" max-width="500" src="../assets/mobile.png" alt="">
              <div class="d-flex align-end justify-center pContainer">
                <v-img class="" min-width="446" max-width="446" src="../assets/Mask group.png"></v-img>
              </div> 
            </v-img>
          </v-col>

          <!-- <v-col class="d-flex align-end justify-end pe-15 fillHome" cols="6">
            <v-img max-width="500" src="../assets/mobilelogo2.png" alt="">
            </v-img>
          </v-col> -->

          <v-col class="d-flex align-start justify-end flex-column marginb" cols="6">
            <p class="font1">Eat Time</p>
            <p class="font2">Scan di Tempat<br>
              Pesan di Tempat<br>
              Bayar di Tempat</p>
            <p class="font3">Bebas Antri</p>

            <v-img max-width="344" max-height="133" src="../assets/googleplaylogo.png" ></v-img>
          </v-col>
        </v-row>
        
      </div>
    </v-img>

    <div class="marginb">
      <v-row style="padding: 40px;" >
        <v-col class="d-flex justify-end pe-15" cols="6">
          <img src="../assets/logohome2.png" />
        </v-col>

        <v-col class="d-flex justify-center flex-column" cols="6">
          <p class="font4 text-justify">Eat Time</p>

          <p class="font5">Sediakan makanan kepada pengunjung anda tidak perlu <br>
            berlama lama dan melakukan antrian yang panjang.<br>
            Cukup sediakan barcode di meja makan, scan menu,<br>
            pilih menu lalu bayar menggunakan E-Wallet.<br>
            EatTime memudahkan restoran anda sekaligus <br>
            pengunjung anda dalam memesan makanan</p>
          
        </v-col>
      </v-row>
    </div>

    <div style="background-color: #9D8686;" class="paddingb3">
      <div class="paddingt paddingb">
         <p class="font6">MEMPERMUDAH KONSUMEN</p>
      </div>

      <v-row >
        <v-col class="d-flex justify-end paddingr" cols="5">
          <img width="172" height="732" src="../assets/timelinelogo.png" />
        </v-col>

        <v-col cols="7">
          <p class="font7">Scan Menu di Meja</p>
          <p class="font8 paddingb2">Pengunjung melakukan scan barcode menggunakan smartphone yang disediakan pada setiap meja</p>

          <p class="font7">Pilih Menu</p>
          <p class="font8 paddingb">Pengunjung memilih menu yang ada di aplikasi yang langsung terintegrasi pada tablet yang ada di kasir</p>

          <p class="font7">Bayar Ditempat</p>
          <p class="font8">Pengunjung dapat melakukan pembayaran menggunakan e-money ataupun secara cash dengan waiter akan mengambil uang saat mendatangkan pesanan</p>

          <br>

        </v-col>
      </v-row>
    </div>

    <div style="background-color: #7D5A5A; padding-top: 100px; padding-left: 40px;padding-right: 40px;">
    
      <div class="text-center">
        <p class="font6">KELOLA PESANAN DENGAN MUDAH</p>
   
        <br>

        <div class="d-flex justify-center pb-5">
        <p class="font9">Proses pesanan anda secara fleksibel. 
          Anda dapat menentukan jika menu telah habis dan menambahkan diskon pada setiap menu tertentu. 
          Anda juga dapat membuat struk dalam bentuk digital dan cetak.</p>
        </div>

        <br>

        <v-img class="mx-auto" style="max-width: 700px;" src="../assets/ipadlogo.png" ></v-img>
      </div>
    </div>

    <div style="background-color: #292121; padding:100px 40px 130px 40px; text-align: center;">
      <v-row>
        <v-col class="d-flex justify-end align-center" cols="6">
          <p class="font1">Eat Time</p>
        </v-col>

        <v-col class="d-flex flex-column align-start justify-center paddingl" cols="6">
          <p class="font2">Scan di Tempat<br>
            Pesan di Tempat<br>
            Bayar di Tempat</p>
          <p class="font3">Bebas Antri</p>
        </v-col>

        <v-img class="mx-auto mt-14 mb-12" max-width="344" max-height="133" src="../assets/googleplaylogo.png" ></v-img>
      </v-row>

      <hr class="hrline mx-auto mb-14">
      <p class="font10 mb-14">Ikuti Sosial Media Kami</p>

      <v-row class="justify-center">
        <img class="" style="max-width: 200px; height: 100px;" src="../assets/iglogo.png"/>
        <p class="font11 ms-15">@eattimeapp</p>
      </v-row>
    </div>

    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import header from '../components/Header.vue';
import footer from '../components/Footer.vue';

  export default {
    name: 'test-page',
    components : {
      'Header':header,
      'Footer':footer,

    },

  data (){
    return{

    }
  },

  methods : {

  },

  created(){

  }

  }
  
</script>

<style>
.body{
 height: 100%;
}

.content-1a{
  display:flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-left: 60px;
}

.content-1b{
  display:flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  padding-left: 60px;
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
}

.pContainer{
  height: 100%;
}

.paddingt{
  padding-top:100px;
}

.paddingb{
  padding-bottom:85px;
}

.paddingb2{
  padding-bottom:90px;
}

.paddingb3{
  padding-bottom:150px;
}

.paddingr{
  padding-right: 70px;
}

.paddingl{
  padding-left: 140px;
}

.margint{
  margin-top: 100px;
}

.marginb{
  margin-bottom: 120px;
}

.fillHome{
  height: 1278px;
}

.hrline{
  height:4px;
  background-color: white;
  width: 60%;
}

</style>