<template>
  <div class="body fill-height">

  </div>
</template>

<script>

  export default {
    name: 'footer-page',
    components : {

    },

  data (){
    return{

    }
  },

  methods : {

  },

  created(){

  }

  }
  
</script>

<style>

</style>