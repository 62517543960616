<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>

@font-face{
  font-family: louis;
  src: url(../src/font/Louis/Louis\ George\ Cafe.ttf);
  font-style: normal;
  font-weight: normal;
}

@font-face{
  font-family: louis;
  src: url(../src/font/Louis/Louis\ George\ Cafe\ Italic.ttf);
  font-style: italic;
  font-weight: normal;
}

@font-face{
  font-family: louis;
  src: url(../src/font/Louis/Louis\ George\ Cafe\ Bold.ttf);
  font-style: normal;
  font-weight: bold;
}

@font-face{
  font-family: louis;
  src: url(../src/font/Louis/Louis\ George\ Cafe\ Bold\ Italic.ttf);
  font-style: italic;
  font-weight: bold;
}

@font-face{
  font-family: high;
  src: url(../src/font/High/High\ Summit.ttf);
  font-style: normal;
  font-weight: normal;
}

</style>
